import axios from 'axios';
import { ENVIRONMENTS, HTTP_STATUS_CODES, JWT_TOKEN_STORAGE } from '~/constants';
import store from '~/store';
import { login, setInvalidToken } from '~/store/modules/config/auth/actions';

export const envURL = () => {
  const config = {
    URL: process.env.REACT_APP_API_SELLOUT_URL || 'http://env-not-found',
    X_API_KEY: process.env.REACT_APP_API_SELLOUT_X_API_KEY,
  };

  return config;
};

const parseJwt = (token) => {
  if (!token) return null;

  try {
    const base64Url = token.split('.')[1] || '';
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        ?.atob(base64)
        ?.split('')
        ?.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        ?.join(''),
    );

    return jsonPayload && JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};

const isExpiredJWT = (jwt) => {
  if (!jwt) return true;

  const decodedToken = jwt instanceof Object ? jwt : parseJwt(jwt);

  if (!decodedToken) return true;

  const now = new Date();
  const tokenExpiration = new Date((decodedToken?.exp || 0) * 1000);

  if (now > tokenExpiration) return true;

  return false;
};

export const getToken = () => {
  const tag = document.getElementsByTagName('html')[0].baseURI;
  const tagValue = tag?.split('token=')?.[1];

  const TOKEN = tagValue || localStorage.getItem(JWT_TOKEN_STORAGE);

  const TOKEN_DECODED = parseJwt(TOKEN);

  if (isExpiredJWT(TOKEN_DECODED) === false) {
    localStorage.setItem(JWT_TOKEN_STORAGE, TOKEN);
    const profiles = JSON.parse(TOKEN_DECODED?.Profiles || '[]');
    store.dispatch(login(profiles));
  } else {
    localStorage.removeItem(JWT_TOKEN_STORAGE);
    store.dispatch(setInvalidToken(true));
  }

  let response = null;

  switch (process.env.REACT_APP_SCOPE) {
    case ENVIRONMENTS.LOCAL:
    case ENVIRONMENTS.LOCAL_NP:
      response = {
        token: TOKEN_DECODED ? TOKEN : process.env.REACT_APP_API_SELLOUT_TOKEN_JWT_MOCK,
        tokenData: TOKEN_DECODED || parseJwt(process.env.REACT_APP_API_SELLOUT_TOKEN_JWT_MOCK),
      };
      break;
    default:
      response = {
        token: TOKEN_DECODED ? TOKEN : undefined,
        tokenData: TOKEN_DECODED,
      };
  }

  return response;
};

export const Http = () => {
  const { URL, X_API_KEY } = envURL();

  const client = axios.create({
    baseURL: URL,
    timeout: 120000,
  });

  client.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        'accept-language': navigator?.language || navigator?.userLanguage || 'pt-BR',
        'Content-Type': 'application/json; charset=utf-8',
      };

      const { token } = getToken();
      if (token) config.headers.Authorization = `Bearer ${token}`;
      if (X_API_KEY) config.headers['x-api-key'] = X_API_KEY;

      return config;
    },
    (error) => Promise.reject(error),
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response?.status;
      if (!status || status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        store.dispatch(setInvalidToken(true));
      }
      if (status === HTTP_STATUS_CODES.FORBIDDEN) {
        // TODO - Navigate to unauthorized screen
      }

      return Promise.reject(error);
    },
  );

  return client;
};
