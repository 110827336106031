import produce from 'immer';
import { REQUEST_DEALERS } from './patterns';

const INITIAL_STATE = {
  associations: {
    requesting: false,
    error: false,
    list: {
      total: 0,
      dealers: [],
    },
  },
};

export default function dealers(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_DEALERS: {
        draft.associations = {
          requesting: false,
          error: false,
          list: action.payload.data,
        };
        break;
      }
      default:
    }
  });
}
