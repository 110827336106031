import { Export, Gear } from 'phosphor-react';
import i18n from '~/locales';

function t(item) {
  return i18n.logger.options.resources[i18n.resolvedLanguage].routes[item];
}

export const ROUTES = {
  MAIN: {
    ASSOCIATION: '/main/association',
    REPORT: '/main/report/invoice',
  },
  OMEGA: {
    HOME: '/home/page',
  },
  DEVELOPMENT: {
    HOME: '/',
  },
  NOT_AUTHORIZED: 'not-authorized',
};

export const PROFILE_TYPES = {
  MAIN: '/main',
  HOME: '/home',
  NOT_AUTHORIZED: 'not-authorized',
};

export const MENU_MAIN = {
  [ROUTES.MAIN.ASSOCIATION]: [
    {
      title: t('association'),
      icon: <Gear />,
      route: ROUTES.MAIN.ASSOCIATION,
    },
  ],
  [ROUTES.MAIN.REPORT]: [
    {
      title: t('report'),
      icon: <Export />,
      route: ROUTES.MAIN.REPORT,
    },
  ],
};
