import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 70%;
  height: 20px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const ProgressBar = styled.div`
  width: ${({ progress }) => progress || 0.5}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`;

export const ProgressBarStatus = styled.span`
  display: inline-block;
  width: 100%;
  padding: 0 15%;
  margin: 0 !important;
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDark};
  margin-left: 10px;

  &.success {
    color: ${({ theme }) => theme.colors.success};
  }

  &.error {
    color: ${({ theme }) => theme.colors.error};
    font-weight: bold;
  }

  &.warning {
    color: ${({ theme }) => theme.colors.warning};
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.error}!important;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 14px;
  margin: 0 15px;
`;
