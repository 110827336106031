import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import { Http } from './http';

export const GetReport = (params, options = {}) => Http().post(`/account/report`, params, options);

export const GetReportFilters = (params, options = {}) =>
  Http()
    .post(
      `/account/report-filters`,
      { query: jsonToGraphQLQuery(params, { pretty: true }) },
      options,
    )
    .then(({ data }) => data);

export const GetCountryReportFilters = ({ codes, subCodes }, options = {}) => {
  const filters = {};

  if (codes?.length) filters.codes = codes;
  if (subCodes?.length) filters.subCodes = subCodes;

  return GetReportFilters(
    {
      query: {
        countryQuery: {
          __args: {
            filters,
          },
          Countries: {
            id: true,
            code: true,
          },
        },
      },
    },
    options,
  );
};
