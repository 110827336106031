import { unwind, flatten } from '@json2csv/transforms';
import { Parser } from '@json2csv/plainjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getDynamicValueObj } from '~/helpers';

export const generateCSV = (data, fields) => {
  const parser = new Parser({
    header: true,
    transforms: [
      unwind({ paths: ['items'] }),
      flatten({ objects: true, arrays: true, separator: '_' }),
    ],
    fields,
  });

  const csv = parser.parse(data);
  const blob = new Blob([csv], { type: 'text/csv' });

  const filename = `report_${Date.now()}.csv`;
  const url = URL.createObjectURL(blob);
  const elm = document.createElement('a');
  elm.style = 'display: none';
  elm.download = filename;
  elm.href = url;
  elm.click();

  window.URL.revokeObjectURL(url);
};

export const generateExcel = (data, fields) => {
  const prepareFlat = data.reduce((acc, elm) => {
    if (elm.items.length === 1)
      acc.push({
        ...elm,
        items: elm.items[0],
      });

    if (elm.items.length > 1)
      elm.items.forEach((field) => {
        acc.push({
          ...elm,
          items: field,
        });
      });

    return acc;
  }, []);

  const prepareValues = prepareFlat.reduce((acc, elm) => {
    const line = {};
    fields.forEach((field) => {
      line[field.label] = getDynamicValueObj(elm, field.value);
    });

    acc.push(line);

    return acc;
  }, []);

  const worksheet = XLSX.utils.json_to_sheet(prepareValues);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

  // Buffer to store the generated Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });

  saveAs(blob, 'Report.xlsx');
};
