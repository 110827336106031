import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px 5px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.light};

  tbody {
    position: relative;

    tr:nth-child(even) {
      ${({ witEvenChild, theme, withOpenDetails }) =>
        witEvenChild && !withOpenDetails ? `background-color: ${theme.colors.light}` : ''}
    }
  }
`;

export const Header = styled.tr`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  height: 2.5rem;

  > th {
    padding: 0.625rem 1.25rem;
    text-align: center !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.sizes.normal};
    color: ${({ theme }) => theme.colors.white};

    &:last-child {
      text-align: ${({ lastChildRight }) => (lastChildRight ? 'right' : 'center')};
    }
  }
`;

export const Line = styled.tr`
  width: 100%;
  background-color: ${({ theme, customBack }) =>
    customBack ? theme.colors.light : theme.colors.white};
  transition: ${({ theme }) => theme.transition.default};
  height: 45px;
  > td {
    height: 45px;
    padding: 0.375rem 0.625rem 0.375rem 1.25rem;
    color: ${({ theme }) => theme.colors.dark};
    text-align: center !important;
    vertical-align: middle;
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  &:hover {
    ${({ withHover, theme }) =>
      withHover ? `background-color: ${theme.colors.grayLight}!important` : ''}
  }
`;

export const LineDetails = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  //transition: max-height 0.5s;
  overflow: hidden;

  &.--line-visible {
    animation: 0.5s ease-out fadeInLineAnimation 1;
  }

  &.--close-line {
    animation: 0.3s ease-out fadeOutLineAnimation 1;
    max-height: 0;
  }

  &.--line-collapse {
    max-height: 0;
  }
`;

export const Error = styled.div`
  width: 100%;
  height: 10px;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.colors.skeletonBase};
`;

export const Order = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  span {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const OrderIcons = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const Empty = styled.div`
  width: 100%;
  padding: 0.625rem 1.25rem;

  font-weight: ${({ theme }) => theme.font.weight.bold};
`;
