import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';

import { CircleNotch, ArrowRight } from 'phosphor-react';

import { LabelInput, ErrorInput, ErrorContainerInput, LoadingInput } from '~/components/styled';
import i18n from '~/locales';
import { Container, customStyles, CustomOption } from './style';

function t(item) {
  return i18n.logger.options.resources[i18n.resolvedLanguage].default[item];
}

function Option({ children, ...props }) {
  const {
    label,
    value,
    selectProps: { inputValue, value: val },
  } = props;

  const strRegExp = new RegExp(inputValue, 'ig');
  const str = inputValue.length > 0 ? label.replace(strRegExp, `<b>${inputValue}</b>`) : label;

  return (
    <components.Option {...props}>
      <CustomOption active={value === val?.value}>
        <div dangerouslySetInnerHTML={{ __html: str }} />
        <ArrowRight />
      </CustomOption>
    </components.Option>
  );
}

const Select = forwardRef(
  (
    {
      label,
      noOptionsMessage = `${t('noOptions')}`,
      loadingMessage = `${t('searching')}`,
      withFullBorder,
      options,
      value,
      placeholder = 'Select',
      name,
      onChange,
      onInputChange,
      inputValue,
      onMenuOpen,
      onMenuClose,
      isClearable = false,
      disabled = false,
      isMulti = false,
      loading = false,
      autoComplete = false,
      register,
      error,
      ...props
    },
    ref,
  ) => {
    const [selectValue, setSelectValue] = useState(value);

    const {
      onChange: onChangeRegister,
      onBlur,
      ref: refRegister,
      ...restRegister
    } = register ? register(name) : { onChange };

    useEffect(() => {
      setSelectValue(value);
    }, [value]);

    const resetField = () => setSelectValue(null);
    const setValueField = (valueField) => setSelectValue(valueField);

    useImperativeHandle(ref, () => ({
      resetField,
      setValueField,
    }));

    return (
      <Container {...props}>
        {label ? <LabelInput error={error?.message}>{label}</LabelInput> : null}
        <ReactSelect
          placeholder={placeholder}
          styles={customStyles({ withFullBorder, withError: error?.message })}
          isMulti={isMulti}
          value={selectValue}
          options={options}
          isDisabled={disabled}
          isLoading={loading}
          loadingMessage={() => loadingMessage}
          noOptionsMessage={() => (loading ? loadingMessage : noOptionsMessage)}
          isSearchable
          isClearable={isClearable}
          onChange={(option) => {
            setSelectValue(option);
            if (Array.isArray(option)) {
              onChange(option);
              const prepareValues = option.length === 0 ? null : option.map((elm) => elm.value);
              onChangeRegister({
                target: { value: prepareValues, name },
              });
            } else {
              onChange(option);
              if (register)
                onChangeRegister({
                  target: { value: option.value, name },
                });
            }
          }}
          ref={refRegister}
          onInputChange={onInputChange}
          inputValue={inputValue}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          components={
            autoComplete
              ? {
                  Option,
                }
              : {}
          }
          onBlur={onBlur}
          {...restRegister}
        />

        {loading ? (
          <LoadingInput disabled={disabled}>
            <CircleNotch size={20} />
          </LoadingInput>
        ) : null}

        <ErrorContainerInput>
          {error ? <ErrorInput>{error?.message || error}</ErrorInput> : null}
        </ErrorContainerInput>
      </Container>
    );
  },
);

Select.propTypes = {
  /** A label for the dropdown */
  label: PropTypes.string,
  /** A list of options */
  options: PropTypes.any,
  /** Corresponding value for the select in the component */
  value: PropTypes.any,
  /** Provide a text to render after choose an option */
  placeholder: PropTypes.string,
  /** Provide a complete border for input */
  withFullBorder: PropTypes.bool,
  /** Provide a text to render after choose an option */
  name: PropTypes.string,
  /** Provide a function to handler option changes */
  onChange: PropTypes.func,
  /** Provide function to clear option */
  isClearable: PropTypes.bool,
  /** Disable select dropdown */
  disabled: PropTypes.bool,
  /** isMulti select dropdown */
  isMulti: PropTypes.bool,
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Provide function to input change */
  onInputChange: PropTypes.func,
  /** Loading input */
  loading: PropTypes.bool,
  /** Message error */
  error: PropTypes.object,
};

Select.defaultProps = {
  label: '',
  options: [],
  withFullBorder: true,
  value: undefined,
  placeholder: '',
  name: '',
  onChange: () => {},
  onInputChange: () => {},
  isClearable: false,
  disabled: false,
  isMulti: false,
  register: null,
  loading: false,
  error: null,
};

export default Select;
