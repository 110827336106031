import React, { useCallback, useEffect, useState } from 'react';
import { Export, Gear, Intersect, PlusCircle, Users } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ActionButton, Button, SectionSeparator, ShowFilters } from '~/components/atoms';
import { Col, Modal, Table } from '~/components/molecules';
import { requestAssociations } from '~/store/modules/main/associations/actions';

import { toast } from 'react-toastify';
import theme from '~/styles/theme';
import { ActionsTableButtons } from '~/components/styled';
import uniqId from 'uniqid';
import Filter from './Filter';
import ModalAccount from './ModalAccount';
import ModalNewAccount from './ModalNewAccount';
import ModalGrouping from './ModalGrouping';
import {
  ContainerDetails,
  DetailsName,
  DetailsValue,
  GroupDetails,
  Line,
  LineDetails,
} from './style';

function DetailsTable({ line }) {
  const { t } = useTranslation('association');

  return (
    <ContainerDetails>
      <GroupDetails>
        <LineDetails center>
          <DetailsName center>{t('details')}:</DetailsName>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('name')}:</DetailsName>
          <DetailsValue>{line.name}</DetailsValue>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('email')}</DetailsName>
          <DetailsValue>{line.email || '-'}</DetailsValue>
        </LineDetails>{' '}
        <LineDetails>
          <DetailsName>{t('phone')}:</DetailsName>
          <DetailsValue>{line.phone || '-'}</DetailsValue>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('classification')}:</DetailsName>
          <DetailsValue>{line.classification || '-'}</DetailsValue>
        </LineDetails>
      </GroupDetails>

      <Line />

      <GroupDetails>
        <LineDetails center>
          <DetailsName center>{t('documents')}:</DetailsName>
        </LineDetails>
        {line?.documents?.map((elm) => (
          <LineDetails key={uniqId()}>
            <DetailsName>{elm?.type}:</DetailsName>
            <DetailsValue>{elm.value || '-'}</DetailsValue>
          </LineDetails>
        ))}
      </GroupDetails>

      <Line />

      <GroupDetails>
        <LineDetails center>
          <DetailsName center>{t('addressInfo')}:</DetailsName>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('address')}:</DetailsName>
          <DetailsValue>{line?.address?.address || '-'}</DetailsValue>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('country')}:</DetailsName>
          <DetailsValue>{line?.address?.country || '-'}</DetailsValue>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('state')}:</DetailsName>
          <DetailsValue>{line?.address?.state || '-'}</DetailsValue>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('city')}:</DetailsName>
          <DetailsValue>{line?.address?.city || '-'}</DetailsValue>
        </LineDetails>
        <LineDetails>
          <DetailsName>{t('postalCode')}:</DetailsName>
          <DetailsValue>{line?.address?.postalCode || '-'}</DetailsValue>
        </LineDetails>
      </GroupDetails>
    </ContainerDetails>
  );
}

function Association() {
  const { t } = useTranslation('association');
  const dispatch = useDispatch();

  const { associations } = useSelector((state) => state.association);

  const [exportLoading, setExportLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalNewAccount, setIsOpenModalNewAccount] = useState(false);
  const [isOpenModalGrouping, setIsOpenModalGrouping] = useState(false);
  const [choseItem, setChoseItem] = useState(null);
  const [actualId, setActualId] = useState(null);

  const [params, setParams] = useState({
    page: 1,
    country: 'BR',
    onlyAssociates: false,
  });

  const getListAssociations = useCallback(() => {
    dispatch(requestAssociations(params));
  }, [params, dispatch]);

  useEffect(() => {
    getListAssociations();
  }, [getListAssociations]);

  const onChangeSort = (sort) => {
    setParams({ ...params, orderBy: [sort.selector], order: sort.order });
  };

  const onChangePage = (page) => {
    setParams({ ...params, page });
  };

  const onChangeItemsPerPage = (total) => {
    setParams({ ...params, page: 1, perPage: total });
  };

  const toggle = (item, needReload = false) => {
    setChoseItem({ ...item, country: params.country });
    setIsOpenModal(!isOpenModal);
    if (needReload) getListAssociations();
  };

  const toggleNewAccount = (item, needReload = false) => {
    setChoseItem({ ...item, country: params.country });
    setIsOpenModalNewAccount(!isOpenModalNewAccount);
    if (needReload) getListAssociations();
  };

  const toggleGrouping = (item, needReload = false) => {
    setChoseItem({ ...item, country: params.country });
    setIsOpenModalGrouping(!isOpenModalGrouping);
    if (needReload) getListAssociations();
  };

  const exportTable = async () => {
    try {
      setExportLoading(true);

      // const { data } = await ExportAllOrders(params);
      // window.open(data.url, '_blank').focus();
    } catch (err) {
      toast.error(err?.data?.error || err?.data?.message || err?.message || `${t('genericError')}`);
    } finally {
      setExportLoading(false);
    }
  };

  const columns = [
    {
      name: t('name'),
      selector: 'name',
    },
    {
      name: t('email'),
      selector: 'email',
    },
    {
      name: t('document'),
      selector: 'documents',
      custom: (document) =>
        document.map((elm, index) => {
          let prepare = `${elm.type}: ${elm.value}`;
          if (document.length > 1 && index !== document.length - 1) {
            prepare = `${prepare} | `;
          }
          return prepare;
        }),
    },
    {
      name: t('actions'),
      selector: 'id',
      custom: (id, line) => (
        <ActionsTableButtons>
          <ActionButton
            tooltip={t('chooseAccount')}
            id={`performAssociation-${id}`}
            bgColor={theme.colors.primary}
            bgColorHover={theme.colors.primaryDark}
          >
            <Intersect
              size="32"
              color={theme.colors.white}
              onClick={() => {
                setActualId(id);
                toggle(line);
              }}
            />
          </ActionButton>

          <ActionButton
            tooltip={t('chooseAccount')}
            id={`performAssociation-${id}`}
            bgColor={theme.colors.gray}
            bgColorHover={theme.colors.gray100}
          >
            <Users
              size="32"
              color={theme.colors.white}
              onClick={() => {
                setActualId(id);
                toggleGrouping(line);
              }}
            />
          </ActionButton>

          <ActionButton
            tooltip={t('chooseAccount')}
            id={`performAssociation-${id}`}
            bgColor={theme.colors.secondary}
            bgColorHover={theme.colors.secondaryDark}
          >
            <PlusCircle
              size="32"
              color={theme.colors.white}
              onClick={() => {
                setActualId(id);
                toggleNewAccount(line);
              }}
            />
          </ActionButton>
        </ActionsTableButtons>
      ),
    },
  ];

  return (
    <>
      <SectionSeparator icon={<Gear />} title={t('associations')} />

      <ShowFilters filter={<Filter params={params} setParams={setParams} />}>
        <Col className="is-4">
          <Button
            className="is-hidden"
            title={t('exportTable')}
            iconLeft={<Export />}
            onClick={exportTable}
            loading={exportLoading}
            variant="primaryDark"
            fullWidth
          />
        </Col>
      </ShowFilters>
      {isOpenModal ? (
        <Modal
          isOpen={isOpenModal}
          toggle={toggle}
          title={t('performAssociation')}
          subTitle={t('associationSteps')}
          description={`${choseItem?.name} - ${choseItem?.uuid}`}
          maxWidth="950px"
        >
          <ModalAccount selectId={actualId} choseItem={choseItem} toggle={toggle} />
        </Modal>
      ) : null}

      {isOpenModalNewAccount ? (
        <Modal
          isOpen={isOpenModalNewAccount}
          toggle={toggleNewAccount}
          title={t('createNewAccount')}
          subTitle={t('createNewAccountDetails')}
          description={`${choseItem?.uuid}-${choseItem?.name}`}
          maxWidth="950px"
          overflowVisible
        >
          <ModalNewAccount selectId={actualId} choseItem={choseItem} toggle={toggleNewAccount} />
        </Modal>
      ) : null}

      {isOpenModalGrouping ? (
        <Modal
          isOpen={isOpenModalGrouping}
          toggle={toggleGrouping}
          title={t('performInconsistency')}
          subTitle={t('groupingSteps')}
          description={`${choseItem?.name} - ${choseItem?.uuid}`}
          maxWidth="950px"
        >
          <ModalGrouping
            selectId={actualId}
            choseItem={choseItem}
            toggle={toggleGrouping}
            grouping
          />
        </Modal>
      ) : null}

      <Table
        withOpenDetails
        openDetails={(line) => <DetailsTable line={line} />}
        columns={columns}
        data={associations?.list?.accounts}
        totalItems={associations.list.totalCount}
        page={params.page}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        loading={associations?.requesting}
        error={associations?.error}
        retry={getListAssociations}
      />
    </>
  );
}

export default Association;
