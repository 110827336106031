import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from '~/components/molecules';
import { Input } from '~/components/atoms';

export const IntervalTypeOptions = Object.freeze({
  LAST: 'last',
  YEAR: 'year',
  MONTH: 'month',
  DATE: 'date',
  DAY: 'day',
});

function Filter({ variant, register, errors }) {
  const { t } = useTranslation('report');

  switch (variant) {
    case IntervalTypeOptions.DATE:
      return (
        <>
          <Col className="is-3">
            <Input
              type="date"
              name="startDate"
              label={t('startDate')}
              register={register}
              error={errors?.startDate}
            />
          </Col>
          <Col className="is-3">
            <Input
              type="date"
              name="endDate"
              label={t('endDate')}
              register={register}
              error={errors?.endDate}
            />
          </Col>
        </>
      );
    default:
      return <Col style={{ height: '111px' }} />;
  }
}

Filter.displayName = 'Filter';

export default Filter;
