import { combineReducers } from 'redux';

// MAIN
import accounts from './main/accounts/reducer';
import association from './main/associations/reducer';
import dealers from './main/dealers/reducer';
import grouping from './main/grouping/reducer';

// CONFIG
import auth from './config/auth/reducer';

export default combineReducers({
  accounts,
  association,
  auth,
  dealers,
  grouping,
});
