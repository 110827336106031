import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  color: rgb(134, 142, 150) !important;

  &.--error {
    color: rgb(240, 103, 103) !important;
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(46, 45, 55);
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid rgb(181, 194, 200) !important;

  .--error + & {
    border-color: rgb(240, 103, 103) !important;
  }
`;

export const ErrorContainer = styled.div`
  min-height: 0.95rem;
  display: flex;
  align-items: flex-end;
  padding-top: 1.5px;
`;

export const ErrorText = styled.p`
  font-size: 9px;
  color: rgb(240, 103, 103);
  font-weight: 400;
`;

export const SelectStyles = {
  container: (base) => ({
    ...base,
    flex: 1,
    minHeight: '46px',
    padding: '0.2rem 0px',
    fontSize: '18px',
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
    zIndex: 2,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#e6f7ff' : 'transparent',
    color: state.isSelected ? '#1890ff' : '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e6f7ff',
    },
  }),
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: state.data.isFixed ? '#ecedf2' : '#e6f7ff',
    color: state.data.isFixed ? '#009be6' : '#1890ff',
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: '#1890ff',
    fontWeight: state.data.isFixed ? 'bold' : 'normal',
    paddingRight: state.data.isFixed ? '6px' : '0px',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    display: state.data.isFixed ? 'none' : 'flex',
    color: '#1890ff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e6f7ff',
      color: '#ff0000',
    },
  }),
};
