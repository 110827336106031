import { SET_LOGIN, SET_LOGOUT, SET_INVALID_TOKEN } from './patterns';

export function login(profiles, logged = true, invalidToken = false) {
  return {
    type: SET_LOGIN,
    payload: { logged, profiles, invalidToken },
  };
}

export function setInvalidToken(isValid) {
  return {
    type: SET_INVALID_TOKEN,
    payload: { invalidToken: isValid },
  };
}

export function logout() {
  return {
    type: SET_LOGOUT,
  };
}
