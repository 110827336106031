export const DOWNLOAD_STATUS = Object.freeze({
  IDLE: 0,
  ERROR: 1,
  CANCELED: 2,
  FINISHED: 3,
  CONNECTING: 4,
  STARTED: 5,
});

export const FILE_TYPE = {
  CSV: 'CSV',
  XLS: 'XLS',
};
