import React from 'react';
import { Routes as ReactDOMRoutes, Route } from 'react-router-dom';
import { ROUTES } from '~/constants';

// ADMIN
import { Association } from '~/modules/Main';
import { InvoiceReport } from '~/modules/Main/Report';

// HOME
import { Home as HomePage } from '~/modules/Omega';

import { NotAuthorized, Wrapper } from '~/components/organisms';
import { Home } from '~/modules/Development';

function Routes() {
  return (
    <Wrapper>
      <ReactDOMRoutes>
        <Route path={ROUTES.DEVELOPMENT.HOME} exact element={<Home />} />

        {/* HOME */}
        <Route path={ROUTES.OMEGA.HOME} element={<HomePage />} />

        {/* MAIN */}
        <Route path={ROUTES.MAIN.ASSOCIATION} element={<Association />} />
        <Route path={ROUTES.MAIN.REPORT} element={<InvoiceReport />} />

        <Route path={ROUTES.NOT_AUTHORIZED} element={<NotAuthorized />} />
      </ReactDOMRoutes>
    </Wrapper>
  );
}

export default Routes;
