import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  background-color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.white : theme.colors.disabled};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.font.sizes.medium};

  ${({ theme, withFullBorder, withError, isFile }) =>
    !withFullBorder
      ? css`
          border-bottom: 1px solid ${withError ? theme.colors.error : theme.colors.gray};
        `
      : css`
          border-radius: 5px;
          padding: ${isFile ? '0' : '0 10px'};
          border: 1px solid ${withError ? theme.colors.error : theme.colors.gray}!important;
        `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray};
  }

  &:focus-within {
    border-bottom: 2px solid
      ${({ theme, withError }) => (withError ? theme.colors.error : theme.colors.gray)};
  }

  > svg,
  i {
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.font.sizes.large};
  }

  > img {
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    right: 15px;
    pointer-events: none;
  }

  > div {
    input::placeholder {
      color: #868e9699;
    }
  }
`;

export const InputIconLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.textTertiary} !important;
  margin-right: 10px;
`;

export const LabelInput = styled.label`
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.textTertiary} !important;
`;

export const ContainerLabelFloat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  min-height: 87.9px;

  label.--label-float-input {
    ${({ filled }) =>
      filled &&
      css`
        transform: translate(-3px, -10px) scale(0.9);
      `}
  }

  &:focus-within {
    label.--label-float-input {
      transform: translate(-3px, -10px) scale(0.9);
    }
  }
`;

export const LabelFloat = styled.label`
  position: absolute;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ error, theme }) => (error ? theme.colors.error : theme.colors.gray)} !important;
  font-size: ${({ theme }) => theme.font.sizes.small};
  line-height: 1;
  left: 4px;
  top: 16px;
`;

export const ErrorContainerInput = styled.div`
  min-height: 0.95rem;
  display: flex;
  align-items: flex-end;
  padding-top: 1.5px;
`;

export const ErrorInput = styled.p`
  font-size: ${({ theme }) => theme.font.sizes.xxSmall};
  color: ${({ theme }) => theme.colors.error};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

export const LoadingInput = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  width: 36px;
  height: 36px;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  svg {
    color: ${({ theme }) => theme.colors.primary} !important;
    background-color: ${({ theme }) => theme.colors.transparent} !important;
    animation: rotation 1s linear infinite;
    font-size: 13pt;
  }
`;

export const Box = styled.div`
  background: rgba(200, 200, 200, 0.19);
  border: 1px solid #d7d6d6;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 20px;
  position: relative;

  div.column {
    padding-top: 2px;
    padding-bottom: 0;
  }
`;

export const BoxFilters = styled.div`
  background: rgba(200, 200, 200, 0.19);
  border-radius: 4px;
  padding: 24px;

  div.column {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const BoxFiltersContainerButton = styled.div`
  margin-top: 16px;
  button {
    margin-top: 8px;
  }
`;

export const ActionsTableButtons = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 15px;
  gap: 10px;
  width: 100%;

  img {
    width: 16px;
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const TableActionIconBtn = styled.div`
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  &:hover {
    background-color: ${({ bgColorHover }) => bgColorHover};
  }

  svg.--loading-btn {
    animation: rotation 0.8s linear infinite;
  }
`;

export const CanceledBySupplier = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;
