import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFieldArray } from 'react-hook-form';
import { Trash } from 'phosphor-react';

import { Form, Col, Row } from '~/components/molecules';
import { YES_NO, CLASSIFICATIONS, DOCUMENT_OPERATOR, DOCUMENT_TYPES } from '~/constants';
import { Button, Input, Select } from '~/components/atoms';
import { useFormHook } from '~/hooks';
import { BoxFilters, BoxFiltersContainerButton } from '~/components/styled';
import { AddContainer, Line, OperatorContent, SpecialRow, TrashContainer } from './style';

function Filter({ params, setParams, choseItem }) {
  const { t } = useTranslation('association');

  const REQUIRED_FIELD = `${t('requiredField')}`;
  const DEFAULT_MIXED = Yup.mixed().required(REQUIRED_FIELD).nullable();
  const DEFAULT_STRING = Yup.string().required(REQUIRED_FIELD).nullable();

  const refCountry = useRef();
  const refHeadquarter = useRef();
  const refClassification = useRef();
  const refDocumentOperator = useRef();
  const refDocumentType = useRef();

  const [operatorDefaultValue, setOperatorDefaultValue] = useState(null);

  const schema = Yup.object().shape({
    groupName: Yup.string(),
    groupCode: Yup.string(),
    sourceId: Yup.string(),
    country: Yup.mixed(),
    headquarter: Yup.mixed(),
    classifications: Yup.mixed(),
    documentOperator: Yup.mixed(),
    documents: Yup.array().of(
      Yup.object().shape({
        type: DEFAULT_MIXED,
        value: DEFAULT_STRING,
      }),
    ),
  });

  const COUNTRIES = [
    {
      value: 'BR',
      label: 'BRASIL',
    },
  ];

  const { formFunctions } = useFormHook(schema, { country: COUNTRIES[0].value });

  const {
    register,
    reset,
    control,
    formState: { errors },
  } = formFunctions;

  const {
    fields: fieldsDocument,
    append: appendDocument,
    remove: removeDocument,
  } = useFieldArray({ control, name: 'documents' });

  const addDocumentFields = () => {
    appendDocument({
      type: null,
      value: null,
    });
  };
  const remove = (index) => {
    removeDocument(index);
  };

  useEffect(() => {
    if (choseItem?.documents?.length > 0) {
      choseItem.documents.forEach((elm) => {
        appendDocument({
          type: elm?.governmentTaxCode ?? elm.type,
          value: elm.value,
        });
      });
      setOperatorDefaultValue(DOCUMENT_OPERATOR[1]);
    }
  }, [choseItem]);

  const clearDocuments = () => {
    fieldsDocument.forEach((elm, index) => {
      removeDocument(index);
    });
  };

  const applyFilters = (obj) => {
    let paramsFilter = {};
    for (const item in obj) {
      paramsFilter = { ...paramsFilter, [item]: obj[item] };
    }

    const newParams = { ...params, ...paramsFilter, page: 1 };

    if (newParams?.classification) newParams.classification = [newParams.classification];

    Object.keys(newParams).forEach((k) => {
      if (newParams[k] === undefined || newParams[k] === null || newParams[k] === '')
        delete newParams[k];
    });

    setParams(newParams);
  };

  const getDocumentForType = (value) => {
    return DOCUMENT_TYPES.find((elm) => elm.value === value);
  };

  const getAssociation = (value) => {
    return CLASSIFICATIONS.find((item) => item.value === value.classification.toUpperCase());
  };

  return (
    <BoxFilters>
      <Form onSubmit={applyFilters} schema={schema} formFunctions={formFunctions} withCustomSubmit>
        <Row>
          <Col className="is-4">
            <Input name="groupName" label={t('groupName')} register={register} />
          </Col>

          <Col className="is-4">
            <Input name="groupCode" label={t('groupCode')} register={register} />
          </Col>

          <Col className="is-4">
            <Input name="sourceId" label={t('Source Id')} register={register} />
          </Col>

          <Col className="is-4">
            <Select
              ref={refCountry}
              label={t('country')}
              options={COUNTRIES}
              value={COUNTRIES[0]}
              name="country"
              register={register}
            />
          </Col>

          <Col className="is-4">
            <Select
              ref={refHeadquarter}
              label={t('headquarter')}
              options={YES_NO}
              name="country"
              register={register}
            />
          </Col>

          <Col className="is-4">
            <Select
              ref={refClassification}
              label={t('accountClassification')}
              options={CLASSIFICATIONS}
              value={getAssociation(choseItem)}
              name="classifications"
              register={register}
            />
          </Col>

          <Col className="is-12">
            <Line />
          </Col>

          <Col className="is-12">
            <Row>
              <Col className="is-8">
                {fieldsDocument.map((elm, index) => (
                  <SpecialRow key={elm.id}>
                    <Col className="is-1">
                      <TrashContainer>
                        <Trash onClick={() => remove(index)} />
                      </TrashContainer>
                    </Col>

                    <Col className="is-5">
                      <Select
                        ref={refDocumentType}
                        label={t('documentType')}
                        options={DOCUMENT_TYPES}
                        value={elm.type ? getDocumentForType(elm.type) : null}
                        name={`documents.${index}.type`}
                        register={register}
                        error={errors?.documents?.[index]?.type}
                      />
                    </Col>

                    <Col className="is-6">
                      <Input
                        label={t('document')}
                        register={register}
                        name={`documents.${index}.value`}
                        error={errors?.documents?.[index]?.value}
                      />
                    </Col>
                  </SpecialRow>
                ))}
              </Col>
              <Col className="is-4">
                <OperatorContent hide={fieldsDocument.length < 2}>
                  <Select
                    ref={refDocumentOperator}
                    label={t('documentOperator')}
                    options={DOCUMENT_OPERATOR}
                    name="documentOperator"
                    value={operatorDefaultValue}
                    onChange={(v) => setOperatorDefaultValue(v)}
                    register={register}
                  />
                </OperatorContent>
              </Col>
            </Row>
          </Col>

          <Col className="is-12">
            <AddContainer marginTop={fieldsDocument.length > 0}>
              <Button title={t('addDocumentFields')} onClick={addDocumentFields} />
            </AddContainer>
          </Col>

          <Col className="is-12">
            <Line />
          </Col>
        </Row>

        <Row>
          <Col className="is-6">
            <BoxFiltersContainerButton>
              <Button type="submit" variant="secondary" fullWidth title={t('apllyFilters')} />
            </BoxFiltersContainerButton>
          </Col>
          <Col className="is-6">
            <BoxFiltersContainerButton>
              <Button
                variant="error"
                onClick={() => {
                  reset();
                  refHeadquarter.current.resetField();
                  refClassification.current.resetField();
                  refDocumentOperator.current.resetField();
                  clearDocuments();

                  applyFilters({
                    groupName: null,
                    groupCode: null,
                    country: COUNTRIES[0].value,
                    headquarter: null,
                    classification: null,
                    documentOperator: null,
                    documents: [],
                  });
                }}
                fullWidth
                title={t('clearFilters')}
              />
            </BoxFiltersContainerButton>
          </Col>
        </Row>
      </Form>
    </BoxFilters>
  );
}

export default Filter;
