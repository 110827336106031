import React, { useState } from 'react';
import uniqId from 'uniqid';
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { createListMock } from '~/helpers';
import theme from '~/styles/theme';
import { CaretDown, CaretUp } from 'phosphor-react';
import { ActionsTableButtons } from '~/components/styled';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '~/hooks';
import Pagination from '../Pagination';
import Skeleton from '../Skeleton';
import { ActionButton, Retry } from '../../atoms';
import * as S from './style';
import { LineDetails } from './style';

const ORDER_SORT = {
  ASC: 'asc',
  DESC: 'desc',
};

function ListTable({
  columns = [],
  data = [],
  lastChildRight,
  withPagination = true,
  withOpenDetails = false,
  openDetails,
  loading,
  error,
  totalItems = 0,
  itemsPerPage = 10,
  page = 1,
  onChangeItemsPerPage = () => {},
  onChangePage = () => {},
  onChangeSort = () => {},
  retry = () => {},
}) {
  const { t } = useTranslation('default');

  const [selectorSort, setSelectorSort] = useState(null);
  const [orderSort, setOrderSort] = useState(ORDER_SORT.DESC);
  const [open, setOpen] = useState(null);
  const prevOpen = usePrevious(open);

  const defineSort = (selector) => {
    setSelectorSort(selector);
    if (selector !== selectorSort) {
      setOrderSort(ORDER_SORT.ASC);
      onChangeSort({ selector, order: ORDER_SORT.ASC });
    } else {
      switch (orderSort) {
        case ORDER_SORT.ASC:
          setOrderSort(ORDER_SORT.DESC);
          onChangeSort({ selector, order: ORDER_SORT.DESC });
          break;
        case ORDER_SORT.DESC:
          setOrderSort(ORDER_SORT.ASC);
          onChangeSort({ selector, order: ORDER_SORT.ASC });
          break;
        default:
          break;
      }
    }
  };

  return (
    <S.Container>
      <S.Table
        lastChildRight={lastChildRight}
        witEvenChild={!loading}
        withOpenDetails={withOpenDetails}
      >
        <thead>
          <S.Header>
            {withOpenDetails && (
              <th>
                <S.Order>
                  <span>{t('details')}</span>
                </S.Order>
              </th>
            )}
            {columns.map((elm) => (
              <th key={uniqId()}>
                <S.Order>
                  <span onClick={() => (elm.order ? defineSort(elm.order) : null)}>{elm.name}</span>
                  {elm.order ? (
                    <S.OrderIcons>
                      {selectorSort !== elm.order && <TiArrowUnsorted />}
                      {selectorSort === elm.order && orderSort === ORDER_SORT.NONE && (
                        <TiArrowUnsorted />
                      )}
                      {selectorSort === elm.order && orderSort === ORDER_SORT.ASC && (
                        <TiArrowSortedDown />
                      )}
                      {selectorSort === elm.order && orderSort === ORDER_SORT.DESC && (
                        <TiArrowSortedUp />
                      )}
                    </S.OrderIcons>
                  ) : null}
                </S.Order>
              </th>
            ))}
          </S.Header>
        </thead>

        <tbody>
          {loading
            ? createListMock(itemsPerPage).map(() => (
                <S.Line key={uniqId()} withHover={false}>
                  {columns.map(() => (
                    <td key={uniqId()}>
                      <Skeleton height={30} />
                    </td>
                  ))}
                  {withOpenDetails && (
                    <td>
                      <Skeleton height={30} />
                    </td>
                  )}
                </S.Line>
              ))
            : null}

          {!loading && error
            ? createListMock(itemsPerPage).map(() => (
                <S.Line key={uniqId()} withHover={false}>
                  {columns.map(() => (
                    <td key={uniqId()}>
                      <S.Error />
                    </td>
                  ))}
                  {withOpenDetails && (
                    <td>
                      <S.Error />
                    </td>
                  )}
                </S.Line>
              ))
            : null}

          {!loading && error ? <Retry retry={retry} /> : null}

          {!loading && !error && data.length > 0
            ? data.map((elm, index) => (
                <React.Fragment key={uniqId()}>
                  <S.Line withHover customBack={withOpenDetails && index % 2}>
                    {withOpenDetails && (
                      <td>
                        <ActionsTableButtons>
                          <ActionButton
                            bgColor={theme.colors.primary}
                            bgColorHover={theme.colors.primaryDark}
                          >
                            {open === index ? (
                              <CaretUp
                                size="32"
                                color={theme.colors.white}
                                onClick={() => {
                                  setOpen(null);
                                }}
                              />
                            ) : (
                              <CaretDown
                                size="32"
                                color={theme.colors.white}
                                onClick={() => {
                                  setOpen(index);
                                }}
                              />
                            )}
                          </ActionButton>
                        </ActionsTableButtons>
                      </td>
                    )}
                    {columns.map((col) => {
                      const field = elm[col.selector];
                      const isValidField =
                        col.selector !== undefined && field !== undefined && field !== null;
                      const value = isValidField ? elm[col.selector] : '-';

                      return (
                        <td key={uniqId()}>
                          {col.custom ? col.custom(value, elm, index) : value.toString()}
                        </td>
                      );
                    })}
                  </S.Line>
                  {withOpenDetails ? (
                    <tr>
                      <td colSpan={columns.length + 1}>
                        <LineDetails
                          className={
                            open === index
                              ? '--line-visible'
                              : prevOpen === index
                              ? '--close-line'
                              : '--line-collapse'
                          }
                        >
                          {openDetails && openDetails(elm)}
                        </LineDetails>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              ))
            : null}
          {!loading && !error && data.length === 0 ? (
            <S.Line withHover={false}>
              <td colSpan={columns.length + 1}>
                <S.Empty>{t('noResultsFound')}</S.Empty>
              </td>
            </S.Line>
          ) : null}
        </tbody>
      </S.Table>

      {withPagination ? (
        <Pagination
          totalItems={totalItems}
          page={page}
          itemsPerPage={itemsPerPage}
          onChangeItemsPerPage={onChangeItemsPerPage}
          onChangePage={onChangePage}
        />
      ) : null}
    </S.Container>
  );
}

export default ListTable;
