import React from 'react';

import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

import * as S from './styles';

const SelectAsync = React.forwardRef(
  (
    {
      delay = 700,
      error,
      iconLeft,
      isClearable = true,
      isLoading,
      isMulti = false,
      isSearchable = true,
      label,
      loadingMessage,
      loadOptions,
      minChar = 1,
      maxChar,
      name,
      minCharMessage,
      maxCharMessage,
      noOptionsMessage,
      onBlur,
      onChange,
      register,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation('default');
    const [$isLoading, setIsLoading] = React.useState(false);
    const {
      onChange: onChangeRegister,
      onBlur: onBlurRegister,
      ...restRegister
    } = register ? register(name) : { onChange };

    const $onChange = (values) => {
      if (onChange) {
        onChange(values);
      }

      if (register) {
        onChangeRegister({
          target: { value: values?.map((elm) => elm.value) || [], name },
        });
      }
    };

    const $onBlur = (event) => {
      if (onBlur) onBlur(event);
      if (register) onBlurRegister(event);
    };

    const $loadOptions = (inputValue, callback) => {
      clearTimeout($loadOptions.delay);

      if (!inputValue) return callback([]);
      if (minChar && minChar > inputValue?.length) return callback([]);
      if (maxChar && maxChar < inputValue?.length) return callback([]);

      $loadOptions.delay = setTimeout(() => {
        setIsLoading(true);

        const isAsync = loadOptions.constructor.name === 'AsyncFunction';

        if (isAsync) {
          loadOptions(inputValue)
            .then(callback)
            .catch(callback)
            .finally(() => setIsLoading(false));
        } else {
          loadOptions(inputValue, callback);
          setIsLoading(false);
        }
      }, delay);
    };

    const $noOptionsMessage = ({ inputValue }) => {
      if (isSearchable === false) return t('selectNoOptionsMessage');

      if (minChar > inputValue?.length)
        return minCharMessage ?? t('selectMinCharMessage', { minChar });

      if (maxChar && maxChar < inputValue?.length)
        return maxCharMessage ?? t('selectMaxCharMessage', { maxChar });

      return noOptionsMessage ? noOptionsMessage(inputValue) : t('selectNoOptionsFoundMessage');
    };

    return (
      <>
        <S.Label className={error ? '--error' : ''}>{label}</S.Label>
        <S.ContainerSelect>
          {iconLeft || null}
          <AsyncSelect
            isClearable={isClearable}
            isLoading={isLoading ?? $isLoading}
            isMulti={isMulti}
            isSearchable={isSearchable}
            loadingMessage={() => loadingMessage ?? t('selectLoadingMessage')}
            loadOptions={$loadOptions}
            name={name}
            noOptionsMessage={$noOptionsMessage}
            onBlur={$onBlur}
            onChange={$onChange}
            openMenuOnClick={false}
            openMenuOnFocus={false}
            ref={ref}
            styles={S.SelectStyles}
            {...rest}
            {...restRegister}
          />
        </S.ContainerSelect>
        <S.ErrorContainer>
          <S.ErrorText>{error || ''}</S.ErrorText>
        </S.ErrorContainer>
      </>
    );
  },
);

SelectAsync.displayName = 'SelectAsync';

SelectAsync.propTypes = {
  delay: PropTypes.number,
  loadOptions: PropTypes.func.isRequired,
  iconLeft: PropTypes.element,
  minChar: PropTypes.number,
  maxChar: PropTypes.number,
};

SelectAsync.defaultProps = {
  delay: 700,
  iconLeft: undefined,
  minChar: 1,
  maxChar: undefined,
};

export default SelectAsync;
