import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, XCircle } from 'phosphor-react';
import { toast } from 'react-toastify';

import { Col, Row } from '~/components/molecules';
import { Button } from '~/components/atoms';

import * as S from './style';
import { DOWNLOAD_STATUS, FILE_TYPE } from './constants';
import DownloadReport from './DownloadReport';
import { generateCSV, generateExcel } from './SaveReport';

const INITIAL_STATE = {
  message: '',
  consumed: 0,
  duration: 0,
  status: DOWNLOAD_STATUS.IDLE,
};

const getFields = (translation) => [
  // Dealer
  { label: translation('csvHeader_dealerName'), value: 'dealerEntity_name' },
  { label: translation('csvHeader_dealerDocumentNumber'), value: 'dealerEntity_document_number' },
  { label: translation('csvHeader_dealerDocumentType'), value: 'dealerEntity_document_type_name' },
  { label: translation('csvHeader_dealerAddressCity'), value: 'dealerEntity_addressEntity_city' },
  { label: translation('csvHeader_dealerAddressState'), value: 'dealerEntity_addressEntity_state' },

  // Sold
  { label: translation('csvHeader_invoiceDate'), value: 'date' },
  { label: translation('csvHeader_currencyCode'), value: 'currency_code' },
  { label: translation('csvHeader_currencyName'), value: 'currency_name' },
  { label: translation('csvHeader_invoiceType'), value: 'type' },
  { label: translation('csvHeader_invoiceNumber'), value: 'number' },
  { label: 'STATUS', value: 'status' },

  // Items
  { label: translation('csvHeader_quantity'), value: 'items_quantity' },
  { label: translation('csvHeader_totalAmount'), value: 'items_amount' },
  { label: translation('csvHeader_productValue'), value: 'items_productValue' },
  { label: translation('csvHeader_cfopCode'), value: 'items_cfop_code' },
  { label: 'SKU', value: 'items_product_manufacturerCode' },
  { label: translation('csvHeader_productDescription'), value: 'items_product_description' },

  // Custumer
  { label: translation('csvHeader_customerName'), value: 'customerEntity_name' },
  { label: translation('csvHeader_partnerGroup'), value: 'externalMap' },
  {
    label: translation('csvHeader_customerAddressCity'),
    value: 'customerEntity_addressEntity_city',
  },
  {
    label: translation('csvHeader_customerAddressState'),
    value: 'customerEntity_addressEntity_state',
  },
  {
    label: translation('csvHeader_customerDocumentNumber'),
    value: 'customerEntity_document_number',
  },
  {
    label: translation('csvHeader_customerDocumentType'),
    value: 'customerEntity_document_type_name',
  },
];

function ModalDownload({ choseItem, fileType, toggle }) {
  const { t } = useTranslation('report');
  const [statusDownload, setStatusDownload] = useState(INITIAL_STATE);
  const totalRecords = useRef(0);
  const cancelToken = useRef(false);

  const fields = getFields(t);

  const onStartDownload = async () => {
    try {
      totalRecords.current = 0;
      setStatusDownload((s) => ({
        ...s,
        message: t('downloadConnecting'),
        status: DOWNLOAD_STATUS.CONNECTING,
      }));

      const threads = Array.from(
        // eslint-disable-next-line radix
        { length: parseInt(process.env.REACT_APP_DOWNLOAD_THREADS || '6') },
        (_, threadId) =>
          new DownloadReport(threadId, setStatusDownload, t('downloadStarted')).start(
            choseItem,
            totalRecords,
            cancelToken,
          ),
      );

      const result = await Promise.all(threads).catch((err) => ({ error: err }));

      if (result?.error) {
        setStatusDownload({
          ...INITIAL_STATE,
          status: DOWNLOAD_STATUS.ERROR,
          message: t('downloadError'),
        });
        toast.error(t('downloadError'));
        console.error('ModalDownload:Error', { error: result?.error });
        return;
      }

      if (cancelToken.current) {
        toast.warning(t('downloadUserAbort'));
        return;
      }

      const prepareValues = result.reduce((acc, value) => acc.concat(...value), []);

      const manipulateItems = prepareValues.map((elm) => ({
        ...elm,
        status: elm.status ? t('active') : t('inactive'),
        externalMap: Array.isArray(elm.externalMap) ? elm.externalMap[0]?.value : '',
        items: elm.items.map((item) => ({
          ...item,
          productValue: (Math.round((item.amount / item.quantity) * 100) / 100)
            .toString()
            .replace('.', ','),
        })),
      }));

      if (manipulateItems.length > 0) {
        if (fileType === FILE_TYPE.CSV) generateCSV(manipulateItems, fields);
        if (fileType === FILE_TYPE.XLS) generateExcel(manipulateItems, fields);

        setStatusDownload({
          ...INITIAL_STATE,
          status: DOWNLOAD_STATUS.FINISHED,
          message: t('downloadFinished'),
        });
        toast.success(t('downloadFinished'));
      } else {
        setStatusDownload({
          ...INITIAL_STATE,
          status: DOWNLOAD_STATUS.FINISHED,
          message: t('searchFinish'),
        });
        toast.success(t('noRecordsToBeDownloaded'));
      }
    } catch (err) {
      toast.error(err?.data?.error || err?.data?.message || err?.message || t('genericError'));
    }
  };

  const onCancel = async () => {
    statusDownload.current = DOWNLOAD_STATUS.CANCELED;
    cancelToken.current = true;
  };

  useEffect(() => {
    onStartDownload();
  }, []);

  return (
    <>
      <Row>
        <Col className="is-12">
          <S.ProgressBarContainer>
            <S.ProgressBar
              progress={
                statusDownload.status === DOWNLOAD_STATUS.FINISHED
                  ? 100
                  : Math.floor((statusDownload.consumed * 100) / statusDownload.total) || 0
              }
            />
          </S.ProgressBarContainer>
          <S.ProgressBarStatus>
            <p>{statusDownload.message}</p>
            <span>
              {statusDownload.status === DOWNLOAD_STATUS.FINISHED
                ? '100'
                : Math.floor((statusDownload.consumed * 100) / statusDownload.total) || 0}
              %
            </span>
          </S.ProgressBarStatus>
        </Col>
      </Row>
      <Row className="is-centered">
        <Col className="is-flex is-justify-content-center">
          {statusDownload.status === DOWNLOAD_STATUS.FINISHED ? (
            <Button
              variant="primary"
              title={t('close')}
              onClick={toggle}
              iconLeft={<ArrowLeft size={22} />}
            />
          ) : (
            <Button
              variant="error"
              title={t('cancel')}
              disabled={statusDownload.status < DOWNLOAD_STATUS.IDLE}
              onClick={onCancel}
              iconLeft={<XCircle size={22} />}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

ModalDownload.displayName = 'ModalDownload';

export default ModalDownload;
