import { REQUEST_DEALERS, SET_LIST_DEALERS } from './patterns';

export function requestDealers(params) {
  return {
    type: REQUEST_DEALERS,
    payload: {
      params,
    },
  };
}

export function setListDealers({ data }) {
  return {
    type: SET_LIST_DEALERS,
    payload: { data },
  };
}
