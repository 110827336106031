import { takeLatest, call, put, all } from 'redux-saga/effects';
import qs from 'qs';

import { GetAccountDealer } from '~/services';
import { CATCH_PAYLOAD } from '~/constants';
import { toast } from 'react-toastify';
import { REQUEST_DEALERS, FETCH_LIST_DEALERS } from './patterns';
import { setListDealers } from './actions';

export function* sagaRequestDealers({ payload }) {
  try {
    const prepareParams = qs.stringify(payload.params);
    const { data } = yield call(() => GetAccountDealer(prepareParams));

    yield put(
      setListDealers({
        data,
      }),
    );
  } catch (err) {
    yield put({ type: FETCH_LIST_DEALERS, payload: CATCH_PAYLOAD });
    toast.error(err?.data?.error || err?.data?.message || err?.message);
  }
}

export default all([takeLatest(REQUEST_DEALERS, sagaRequestDealers)]);
