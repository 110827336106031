import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, InputAutoComplete, Select } from '~/components/atoms';
import { Col, Form, Row } from '~/components/molecules';
import { BoxFiltersContainerButton } from '~/components/styled';
import { CLASSIFICATIONS } from '~/constants';

import { CreateAssociation, GetAccounts } from '~/services';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormHook } from '~/hooks';
import qs from 'qs';

function ModalNewAccount({ choseItem, toggle }) {
  const { t } = useTranslation('association');

  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const REQUIRED_FIELD = `${t('requiredField')}`;

  const schema = Yup.object().shape({
    groupCode: Yup.string().required(REQUIRED_FIELD),
    classification: Yup.mixed().required(REQUIRED_FIELD),
  });

  const { formFunctions } = useFormHook(schema, {});

  const {
    register,
    formState: { errors },
  } = formFunctions;

  const confirm = async (item) => {
    try {
      setLoadingConfirm(true);
      const prepareObj = {
        uuid: choseItem.uuid,
        classification: item.classification,
        groupCode: item.groupCode,
      };
      await CreateAssociation(prepareObj);
      toggle(null, true);
    } catch (err) {
      toast.error(err?.data?.error || err?.data?.message || err?.message);
    } finally {
      setLoadingConfirm(false);
    }
  };

  const callApiAutoComplete = async ({ value, setList, setLoading }) => {
    try {
      setLoading(true);
      const prepareParams = qs.stringify({
        groupNameOrCode: value,
        country: choseItem?.country,
      });

      const { data } = await GetAccounts(prepareParams);

      const prepareList = data?.account?.map((elm) => ({
        value: elm.accountBRs[0].groupCode,
        label: `${elm.accountBRs[0].groupName} - ${elm.accountBRs[0].groupCode}`,
      }));

      setList(prepareList);
    } catch (err) {
      toast.error(err?.data?.error || err?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={confirm} schema={schema} formFunctions={formFunctions} withCustomSubmit>
        <Row>
          <Col className="is-6">
            <InputAutoComplete
              callApi={callApiAutoComplete}
              name="groupCode"
              label={t('groupCode')}
              placeholder={t('selectSearchGroupName')}
              register={register}
              error={errors?.groupCode}
            />
          </Col>

          <Col className="is-6">
            <Select
              label={t('accountClassification')}
              options={CLASSIFICATIONS}
              name="classification"
              register={register}
              error={errors?.classification}
            />
          </Col>
        </Row>

        <Row>
          <Col className="is-12">
            <BoxFiltersContainerButton>
              <Button
                loading={loadingConfirm}
                type="submit"
                variant="secondary"
                fullWidth
                title={t('confirm')}
              />
            </BoxFiltersContainerButton>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ModalNewAccount;
